import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import {
  ClientDietRequest,
  ClientDietResponse,
  DietGroupedResponse,
  MealOption,
} from "./MealSettings";
import { useMealOptionsStore } from "../stores/mealOptionsStore";

const BASE_URL = "/mashie/ClientDietSettings/diet-settings";
const mapMealOptionToClientDietRequest = (
  mealOption: MealOption
): ClientDietRequest => {
  const { mealOptionId, foodFree, toBeOrdered, disabled, dietPreferences } =
    mealOption;

  const dietPreferencesId = dietPreferences.map((p) => p.id);

  return {
    mealOptionId,
    toBeOrdered,
    disabled,
    foodFree,
    dietPreferenceIds: dietPreferencesId,
  };
};

export function useSavePrefs(clientId: string) {
  const url = `${BASE_URL}/${clientId}`;
  const store = useMealOptionsStore();
  const body = store.mealOptions.map((p) =>
    mapMealOptionToClientDietRequest(p)
  );
  const { isPending, error, mutate } = useMutation({
    mutationFn: async () => {
      await axios.post<ClientDietResponse>(url, body);
    },
  });

  return { isPending, error, savePrefs: mutate };
}

export const fetchMealOptions = async (
  clientId: string,
  categoryId: string
): Promise<ClientDietResponse> => {
  const url = `${BASE_URL}/${clientId}/${categoryId}`;
  const response = await axios.get<ClientDietResponse>(url);
  return response.data;
};

export const fetchDietPreferences = async (
  categoryId: string
): Promise<DietGroupedResponse[]> => {
  const url = `${BASE_URL}/diet-preferences/${categoryId}`;
  const response = await axios.get<DietGroupedResponse[]>(url);
  return response.data;
};
