import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import MealOptionsTable from "./Components/MealOptionsTable";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  Button,
  Card,
  Typography,
} from "@mui/material";
import { useMealOptionsStore } from "./stores/mealOptionsStore";
import { AxiosError } from "axios";
import Translate from "../../react-shared/Translations";
import { useSavePrefs } from "./Domain/dietSettingsApi";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ReplayIcon from "@mui/icons-material/Replay";

interface Props {
  pageState: PageState;
}

type PageState = {
  ClientId: string;
  CategoryId: string;
  IsLocked: boolean;
};

export const feature_js_client_diet_settings = (el: string) => {
  var $el = $(el);

  const pageState: PageState = {
    ClientId: $el.attr("ClientId")!,
    CategoryId: $el.attr("CategoryId")!,
    IsLocked: !Boolean($el.attr("IsUnLocked")),
  };

  var element = document.getElementById("client-diet-settings");

  var element = document.getElementById("client-diet-settings");

  // Create a client
  const queryClient = new QueryClient();
  ReactDOM.render(
    <div>
      <QueryClientProvider client={queryClient}>
        <ClientDietSettings pageState={pageState} />
      </QueryClientProvider>
    </div>,
    element
  );
};

export const ClientDietSettings = ({ pageState: pageState }: Props) => {
  const [isLoadingHook, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [exceptionMessage, setException] = useState("");
  const [showHeaderChecked, setShowHeaderChecked] = useState(false);
  const [toBeOrderedHeaderChecked, setToBeOrderedHeaderChecked] =
    useState(false);
  const [foodFreeHeaderChecked, setFoodFreeHeaderChecked] = useState(false);
  const { isPending: isSaving, savePrefs } = useSavePrefs(pageState.ClientId);
  const {
    isModified,
    setAllMealOptionShow,
    setAllMealOptionTobeOrdered,
    setAllMealOptionFoodFree,
    initializeState,
    removeModifiedState,
    mealOptions,
  } = useMealOptionsStore();

  const getMealOptions = async () => {
    try {
      await initializeState(pageState.ClientId, pageState.CategoryId);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setHasError(true);
      setException((err as AxiosError).message);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setIsLoading(true);
    getMealOptions();
  }, []);

  useEffect(() => {
    setShowHeaderChecked(
      mealOptions.length > 0 &&
        mealOptions.every((option) => option.disabled === false)
    );
    setToBeOrderedHeaderChecked(
      mealOptions.length > 0 &&
        mealOptions.every((option) => option.toBeOrdered === true)
    );
    setFoodFreeHeaderChecked(
      mealOptions.length > 0 &&
        mealOptions.every((option) => option.foodFree === true)
    );
  }, [mealOptions]);

  const handleShowHeaderCheckboxChange = (checked: boolean) => {
    setAllMealOptionShow(checked);
    setShowHeaderChecked(checked);
  };

  const handleToBeOrderedHeaderCheckboxChange = (checked: boolean) => {
    setAllMealOptionTobeOrdered(checked);
    setToBeOrderedHeaderChecked(checked);
  };

  const handleFoodFreeHeaderCheckboxChange = (checked: boolean) => {
    setAllMealOptionFoodFree(checked);
    setFoodFreeHeaderChecked(checked);
  };

  const save = (): void => {
    savePrefs();
    removeModifiedState();
  };

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: 99,
        }}
        open={isModified}
      ></Backdrop>

      <Card
        sx={{
          padding: "5px 5px 15px 18px",
          position: "relative",
          zIndex: 100,
        }}
      >
        {isLoadingHook ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : hasError ? (
          <Alert variant="outlined" severity="error">
            <AlertTitle>
              <Typography style={{ fontSize: 16 }}>
                {Translate("Server_Error")}
              </Typography>
            </AlertTitle>
            <Typography style={{ fontSize: 16 }}>{exceptionMessage}</Typography>
          </Alert>
        ) : (
          <MealOptionsTable
            onShowHeaderClick={handleShowHeaderCheckboxChange}
            showHeaderChecked={showHeaderChecked}
            onToBeOrderedHeaderClick={handleToBeOrderedHeaderCheckboxChange}
            toBeOrderedHeaderChecked={toBeOrderedHeaderChecked}
            onFoodFreeHeaderClick={handleFoodFreeHeaderCheckboxChange}
            foodFreeHeaderChecked={foodFreeHeaderChecked}
            isLocked={Boolean(pageState.IsLocked)}
          />
        )}
        <LoadingButton
          hidden={!isModified}
          endIcon={<SaveIcon />}
          loading={isSaving}
          loadingPosition="end"
          onClick={() => save()}
          sx={{
            marginRight: 2,
            marginTop: 2,
            textTransform: "unset",
            fontSize: "unset",
            visibility: !isModified ? "hidden" : "unset",
          }}
          variant="contained"
        >
          {Translate("Save")}
        </LoadingButton>
        <Button
          endIcon={<ReplayIcon />}
          sx={{
            visibility: !isModified ? "hidden" : "unset",
            marginRight: 2,
            marginTop: 2,
            textTransform: "unset",
            fontSize: "unset",
          }}
          onClick={async () => await getMealOptions()}
          variant="contained"
        >
          {Translate("Undo")}
        </Button>
      </Card>
    </>
  );
};
