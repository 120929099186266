import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import {
  DietGroupedResponse,
  DietPreference,
  MealOption,
} from "../Domain/MealSettings";

import {
  fetchDietPreferences,
  fetchMealOptions,
} from "../Domain/dietSettingsApi";
import { MealOptionUtils } from "../Domain/mealOptionsUtil";

type MealOptionsState = {
  isModified: boolean;
  mealOptions: MealOption[];
  dietPreferencesResponse: DietGroupedResponse[];
};

type MealOptionsActions = {
  initializeState: (clientId: string, categoryId: string) => Promise<void>;
  toggleShow: (mealOption: MealOption) => void;
  toggleToBeOrdered: (mealOption: MealOption) => void;
  toggleFoodFree: (mealOption: MealOption) => void;
  modifyDietPreferences: (
    mealOption: MealOption,
    dietPreferences: DietPreference[]
  ) => void;
  modifyDietPreferencesSetAll: (dietPreferences: DietPreference[]) => void;
  setAllMealOptionShow: (checked: boolean) => void;
  setAllMealOptionTobeOrdered: (checked: boolean) => void;
  setAllMealOptionFoodFree: (checked: boolean) => void;
  removeModifiedState: () => void;
};

export const useMealOptionsStore = create<
  MealOptionsState & MealOptionsActions
>()(
  persist(
    (set) => ({
      isModified: false,
      mealOptions: [],
      dietPreferencesResponse: [],
      initializeState: async (clientId: string, categoryId: string) => {
        const clientDietResponse = await fetchMealOptions(clientId, categoryId);
        const dietGroupedResponses = await fetchDietPreferences(categoryId);
        set((state) => {
          state.isModified = false;
          const initialDietPreferences = (state.dietPreferencesResponse =
            dietGroupedResponses);

          let cachedClientId = sessionStorage.getItem("clientId");
          if (cachedClientId == clientId) {
            return {
              mealOptions: clientDietResponse.mealOptions,
              dietPreferencesResponse: initialDietPreferences,
            };
          }
          sessionStorage.setItem("clientId", clientId);

          return {
            mealOptions: clientDietResponse.mealOptions,
            dietPreferencesResponse: initialDietPreferences,
          };
        });
      },
      removeModifiedState: () => {
        set({ isModified: false });
      },

      toggleShow: (mealOption: MealOption) => {
        set((state) => {
          state.isModified = true;
          var updatedMealOptions: MealOption[];
          updatedMealOptions = MealOptionUtils.update(
            state.mealOptions,
            MealOptionUtils.updateDisabled(mealOption, !mealOption.disabled)
          );
          return { mealOptions: updatedMealOptions };
        });
      },

      toggleToBeOrdered: (mealOption: MealOption) => {
        set((state) => {
          state.isModified = true;
          var updatedMealOptions: MealOption[];
          updatedMealOptions = MealOptionUtils.update(
            state.mealOptions,
            MealOptionUtils.updateToBeOrdered(
              mealOption,
              !mealOption.toBeOrdered
            )
          );
          return { mealOptions: updatedMealOptions };
        });
      },

      toggleFoodFree: (mealOption: MealOption) => {
        set((state) => {
          state.isModified = true;
          var updatedMealOptions: MealOption[];
          updatedMealOptions = MealOptionUtils.update(
            state.mealOptions,
            MealOptionUtils.updateFoodFree(mealOption, !mealOption.foodFree)
          );
          return { mealOptions: updatedMealOptions };
        });
      },

      modifyDietPreferences: (
        mealOption: MealOption,
        dietPreferences: DietPreference[]
      ) => {
        set((state) => {
          state.isModified = true;
          const updatedMealOptions = MealOptionUtils.update(
            state.mealOptions,
            MealOptionUtils.updateDietPreferences(mealOption, dietPreferences)
          );
          return { mealOptions: updatedMealOptions };
        });
      },

      modifyDietPreferencesSetAll: (dietPreferences: DietPreference[]) => {
        set((state) => {
          state.isModified = true;
          const updatedMealOptions = MealOptionUtils.updateAll(
            state.mealOptions,
            dietPreferences
          );
          return { mealOptions: updatedMealOptions };
        });
      },

      setAllMealOptionShow: (show: boolean) => {
        set((state) => {
          state.isModified = true;
          const updatedMealOptions = state.mealOptions.map((option) =>
            MealOptionUtils.updateDisabled(option, !show)
          );
          return { mealOptions: updatedMealOptions };
        });
      },
      setAllMealOptionTobeOrdered: (checked: boolean) => {
        set((state) => {
          state.isModified = true;
          const updatedMealOptions = state.mealOptions.map((option) =>
            MealOptionUtils.updateToBeOrdered(option, checked)
          );
          return { mealOptions: updatedMealOptions };
        });
      },
      setAllMealOptionFoodFree: (checked: boolean) => {
        set((state) => {
          state.isModified = true;
          const updatedMealOptions = state.mealOptions.map((option) =>
            MealOptionUtils.updateFoodFree(option, checked)
          );
          return { mealOptions: updatedMealOptions };
        });
      },
    }),
    {
      name: "mealOptions",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
